<template>
  <div class="container">
    <section class="row p-lg-5 p-2">
      <div class="col-md-6 text-start">
        <h1>About Us</h1>
        <div>
          <p>
            We started bottling beetroot juice in 2005 and have been
          supplying beetroot juice for research into dietary nitrate
          supplementation since 2008. In 2009, the University of Exeter
          published the first human study using our Beet It beetroot
          juice for exercise performance, and reported six days of dietary
          nitrate supplementation significantly reduced the oxygen cost
            of exercise by 5% and delayed the onset of fatigue by 16%.
          </p>
          <p>
            Our Beet It Sport shots have since been extensively adopted
          by both the research community (over 300 universities and
          research institutions worldwide) and the elite sports world
          (over 150 professional sports teams) as a natural source and
          controlled dose of dietary nitrate (400mg per 70ml shot).
          </p>
          <p>
          All published studies that have used our products are
          completely independent as we do not fund research, thus the
          research is not subject to funding bias.
          Over one million Beet It shots are now produced annually and
          we export to over 25 countries worldwide, including the USA,
          Australia and China.
          </p>
          <p>
          We believe our Beet It Sport products are the best –
          researched by the best, used by the best;
          </p>

          <p class="fw-bold">
            We supply more than 150 professional multiple-sprint sports
            clubs, including teams in the English Premier League,
            Premiership Rugby Union, International Rugby Union, Super
            Rugby League, NFL, NHL, NBA, and the MLS.
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <img style="max-width: 500px;width: 100%" src="../assets/images/aboutUs.jpg">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style scoped>

</style>