<template>
  <div class="" style="margin: 0">
    Lost?
</div>
</template>
<script>
export default {
  name: "404Page",
  components: { },
  props: {
    // desc: {
    //   type: String,
    //   default: "The page you are looking for does not exist.",
    //   description: "error description",
    // },
  },
  data() {
    return {
      desc: "The page you are looking for does not exist.",
      showModal: false
    }
  },
  mounted() {
    // eslint-disable-next-line no-prototype-builtins
    if(this.$route.params.hasOwnProperty("desc")) {
      this.desc = this.$route.params.desc
    }
  }
}
</script>