<template>
  <div class="container">
    <section class="row p-lg-5 p-2">
      <div class="col-md-6 text-start">
        <h1>Dosing Guidelines</h1>
        <div>
          <p class="fw-bold">
            Dosing guidelines are widely acknowledged to be between
            400mg and 800mg of dietary nitrate per day, consumed for
            three to six consecutive days, with a final dose consumed
            between 60 and 90 minutes prior to training
            and / or competition.
          </p>
          <p>
            Both acute (one day) and chronic (three to six consecutive
            days) supplementation has been shown to improve exercise
            performance10, although the latter dosing regimen is widely
            regarded as optimal for athletes looking to increase the
            likeliness of gaining a performance benefit.
          </p>
          <p>
            Chronic supplementation of dietary nitrate is thought to cause
            physiological adaptations in the skeletal muscle which would
            not otherwise occur with acute supplementation.
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <img style="max-width: 500px;width: 100%" src="../assets/images/Cross-fit-1-web.jpg">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style scoped>

</style>