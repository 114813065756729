<template>
  <div class="main-div" :class="[{'main-bg': ['home', 'checkout'].includes($route.name)}]">
    <base-nav
        type="primary"
        title=""
        :transparent="true"
      container-classes="px-4 container"
      expand
    >
    </base-nav>
    <!-- Navbar -->
    <div>
      <router-view v-slot="{ Component }">
        <keep-alive include="UserAppointments">
          <component :is="Component" :key="$route.fullPath"></component>
        </keep-alive>
      </router-view>
    </div>

    <footer class="py-5 bg-gradient-gray">
      <div class="container">
        <div class="row align-items-start  justify-content-xl-between">
<!--          <div class="col-md-3 text-white"></div>-->
<!--          <div class="col-md-3 text-white"></div>-->
          <div class="col-md-6 text-white text-md-start">
            <h3 class="text-white">{{ $t("BEET IT CYPRUS") }}</h3>
<!--            <a target="_blank" class="link-light text-white-50  text-decoration-none fw-bold" href="https://buy.stripe.com/7sIcN95vGatO1iMaEF">Buy now</a><br>-->
            <router-link to="/nitrate-sport" class="link-light text-white-50  text-decoration-none fw-bold">
              Sport Nitrate 400
            </router-link><br>
            <router-link to="/cherry-regen" class="link-light text-white-50  text-decoration-none fw-bold">
              Regen Cherry
            </router-link>
           <br>
            <router-link to="/about-us" class="link-light text-white-50  text-decoration-none fw-bold">
              About Us
            </router-link>
          </div>
          <div class="col-md-6 text-white text-md-end pt-xs-5">
            <h3 class="text-white">{{ $t("CONTACT US") }}</h3>
            <div>
              <div class=" fw-bold"> <a class="text-decoration-none link-light text-white-50" href="tel:+357 94 068735">+357 94 068735</a></div>
              <div class=" fw-bold"> <a class="text-decoration-none link-light text-white-50" href="mailto:info@beetit.com.cy">info@beetit.com.cy</a></div>
            </div>
          </div>
          <div class="col-xl-12 text-white text-md-start pt-5">
            <div class="copyright text-md-right text-xl-left fw-bolder float-md-start">
              &copy; {{ year }} BeetIt Cyprus
</div>
            <div class="fw-bold float-end">{{ $t("Backed by science and endorsed by athletes") }}</div>
</div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import packageData from "../../package.json"
import BaseButton from "@/components/BaseButton"
import BaseNav from "@/components/BaseNav"

export default {
  name: "top-layout",
  components: {
    BaseNav,
    BaseButton,
    // VueMultiselect
  },
  data() {
    return {
      locale: "",
      version: null,
      locales: [
        {label: "English", value: "en"},
        {label: "Ελληνικά", value: "el"},
        // {label: 'Russian', value: 'ru'}
      ],
      year: new Date().getFullYear(),
      showMenu: false,
    }
  },
  mounted() {
    this.version = packageData.version
    let localeSelected = this.locales.find( l => l.value === this.$store.state.locale)

    if(localeSelected){
      this.locale = localeSelected
    }

  },
  methods: {
    setLocale () {
      // this.$store.state.locale = value
      // console.log("this.locale", this.locale.value)
      this.$i18n.locale = this.locale.value.toLowerCase()
      this.$store.dispatch("setLocale", this.locale.value.toLowerCase())
    },
  },
}
</script>
<style>
.locale-changer select {
  /*background: transparent;*/
  border: none;
  /*color: white;*/
}
.locale-changer{
  margin-left: auto !important;
  cursor: pointer;
  padding-top: 10px;}

.locale-changer select:focus-visible {
  outline: none;
}
.multiselect.small-select .multiselect__select {
  padding: 4px 6px;
  height: 30px;
  width: 30px;
}

.multiselect.small-select .multiselect__tags {
  max-height: 30px;
  padding: 5px 27px 0 4px;
  min-height: 30px;
  background: unset;
}


@media(min-width: 767px)  {
  .mb-only{
    display: none !important;
  }
}

ul.dropdown-menu.show {
  padding: 0;
}
a.active.router-link-exact-active.nav-link.dropdown-item {
  color: white !important;
}
ul.dropdown-menu.show {
  border-radius: 0px 0 5px 5px;
}
</style>