<template>
  <div class="container">
    <section class="row p-lg-5 p-2">
      <div class="col-md-6 text-start">
        <h1>Backed by Science</h1>
        <div>Beet It shots have been independently used in over 300 published medical and sports performance research papers</div>
      </div>
      <div class="col-md-6">
        <iframe title="vimeo-player" src="https://player.vimeo.com/video/215908458?h=e0c496315c" width="100%" height="360" frameborder="0"    allowfullscreen></iframe>
      </div>
    </section>
    <section class="row p-lg-5 p-2 text-start">
      <div class="col-md-6">
        <h5 class="fw-bold text-uppercase"><i class="fa fa-circle pe-2 text-primary"></i>Multi sprint</h5>
        <div style="font-size: 18px">
          Team sports typically involve multiple high-intensity efforts
          of less 30 seconds, interspersed with rest periods of
          greater than 90 seconds. Athletes are required to maintain
          performance during successive high-intensity intervals over
          long periods of time, whilst simultaneously making rapid and
          accurate decisions.
        </div>
      </div>
      <div class="col-md-6">
        <div class="fw-bold">References:</div>
        <ul>
          <li class="pb-2">
            <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/16336007/">
              Spencer M, Bishop D, Dawson B,
              et al. Physiological and metabolic
              responses of repeated-sprint activities.
              Sports Med. 2005; 35:1025–44.
            </a>
          </li>
          <li class="pb-2">
            <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/25846114/">
              Thompson C, Wylie LJ, Fulford
              J, et al. Dietary nitrate improves
              sprint performance and cognitive
              function during prolonged intermittent
              exercise. Eur J Appl Physiol. 20a15;
              115:1825–34.
            </a>
          </li>
          <li class="pb-2">
            <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/26614506/">
              Wylie LJ, Bailey SJ, Kelly J,
              et al. Influence of beetroot juice
              supplementation on intermittent
              exercise performance. Eur J Appl
              Physiol. 2016; 116:415–25.
            </a>
          </li>
        </ul>
      </div>
    </section>
    <section class="row p-lg-5 p-2 text-start">
      <div class="col-md-6">
        <h5 class="fw-bold text-uppercase"><i class="fa fa-circle pe-2 text-primary"></i>ENDURANCE</h5>
        <div style="font-size: 18px">
          Dietary nitrate supplementation via beetroot juice has been
          associated with improvements of 4-25% in exercise time to
          exhaustion and of 1-3% in time trial performances lasting
          greater than 40 minutes in multiple sports, including running,
          cycling and rowing.
        </div>
      </div>
      <div class="col-md-6">
        <div class="fw-bold">References:</div>
        <ul>
          <li class="pb-2">
            <a target="_blank" href="https://www.olympic.org/london-2012/athletics/10000m-men">
              International Olympic Committee.
              London 2012 athletics,10000m
              men final.
            </a>
          </li>
          <li class="pb-2">
            <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/19661447/">
              Bailey SJ, Winyard P, Vanhatalo A,
              et al. Dietary nitrate supplementation
              reduces the O2 cost of low-intensity
              exercise and enhances tolerance to
              high-intensity exercise in humans.
              J Appl Physiol. 2009; 107:1144–55.
            </a>
          </li>
        </ul>
      </div>
    </section>
    <section class="row p-lg-5 p-2 text-start">
      <div class="col-md-6">
        <h5 class="fw-bold text-uppercase"><i class="fa fa-circle pe-2 text-primary"></i>STRENGTH & POWER</h5>
        <div style="font-size: 18px">
          Many sports require an intermittent and / or sustained level
          of high power output for success, such as cycling sprint
          finishes and breakaways, evasion in rugby and football and
          explosive repetitions in Olympic weight lifting.
        </div>
      </div>
      <div class="col-md-6">
        <div class="fw-bold">References:</div>
        <ul>
          <li class="pb-2">
            <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/25199856/">
              Coggan AR, Leibowitz JL,
              Kadkhodayan ADT, et al. Effect of acute
              dietary nitrate intake on knee extensor
              speed and power in healthy men and
              women. Nitric Oxide. 2015; 48:16–21.
            </a>
          </li>
          <li class="pb-2">
            <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/31913252/">
              Williams TD, Martin MP, Mintz JA.
              Et al. Effect of Acute Beetroot Juice
              Supplementation on Bench Press Power,
              Velocity, and Repetition Volume. J
              Strength Cond Res. 2020; 34(4):924-928.
            </a>
          </li>
          <li class="pb-2">
            <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/32507624/">
              Rodríguez-Fernández, A, Castillo, D,
              Raya-González, J, et al. Beetroot juice
              supplementation increases concentric
              and eccentric muscle power output.
              Original Investigation. Journal of
              Science and Medicine in Sport. 2020;
              In Press.
            </a>
          </li>
        </ul>
      </div>
    </section>

    <section class=" p-lg-5 p-2">
    <div class="row justify-content-md-center">
          <div class="col col-md-8">
            <h4 class="fw-bold text-uppercase">UNIVERSITIES & INSTITUTES</h4>
            <div style="font-size: 18px">
              More than 300 universities and research institutions in
              over 30 countries around the world have purchased our
              concentrated beetroot juice for research purposes,
              most notably including:
            </div>
          </div>
    </div>
      <img class="pt-3" src="../assets/images/unis.png" style="max-width: 900px;width: 100%;" />
<!--      <div class="d-flex">-->
<!--        <div class="flex-column text-start">-->
<!--          <div class="fw-bold text-primary">EUROPE:</div>-->
<!--          <ul class="ps-3">-->
<!--            <li class="">University of Exeter, UK</li>-->
<!--            <li class="">William Harvey Research Institute, UK</li>-->
<!--            <li class="">Maastricht University, Netherlands</li>-->
<!--            <li class="">Karolinska Institute, Sweden</li>-->
<!--            <li class="">Masaryk University, Czech Republic</li>-->
<!--            <li class="">Norweigen School of Sport Sciences, Norway</li>-->
<!--            <li class="">University of Basel, Switzerland</li>-->
<!--            <li class="">Université Libre de Bruxelles, Belgium</li>-->
<!--            <li class="">University Hospital Heidelberg, Germany</li>-->
<!--            <li class="">University College Dublin, Ireland</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="flex-column text-start">-->
<!--          <div class="fw-bold text-primary">AUSTRALIA:</div>-->
<!--          <ul class="ps-3">-->
<!--            <li class="">Australian Institute of Sport, Australia</li>-->
<!--            <li class="">University of Sydney, Australia</li>-->
<!--            <li class="">University of Western Australia,</li>-->
<!--            <li class="">Canterbury Christ Church University, New Zealand</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="col col-md-4 text-start">-->
<!--          <div class="fw-bold text-primary">NORTH AMERICA:</div>-->
<!--          <ul class="ps-3">-->
<!--            <li class="">University of British Columbia, Canada</li>-->
<!--            <li class="">University of Pennsylvania, USA</li>-->
<!--            <li class="">Wake Forest University, USA</li>-->
<!--            <li class="">Washington University in St. Louis, USA</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->

    </section>

  </div>
</template>

<script>
export default {
  name: "BackedByScience"
}
</script>

<style scoped>

</style>