<template>
  <modal :show="isOpen" type="midni" @close="closeModal">
    <template #header>
      <h4 class="m-0 w-100 text-start">Select and order</h4>
    </template>
    <div class="listOfProducts">
      <div v-for="(product, index) in products" :key="index" class="product col-md-4">
        <base-button tag="a" target="_blank" :href="product.link" type="empty">

        <div class="product-image">
        <img height="150" :src="product.image" alt="">
        </div>
        <h2 class="product-name">{{ product.name }}</h2>

<!--        <button-->
<!--            class="btn btn-link text-decoration-none fw-bold"-->
<!--            @click="product.quantity -= 1"-->
<!--        >-->
<!--          - -->
<!--        </button>-->
<!--        <div class="cart-product-count">-->
<!--          {{ product.quantity }}-->
<!--        </div>-->

<!--        <button-->
<!--            class="btn btn-link text-decoration-none fw-bold"-->
<!--            @click="product.quantity += 1"-->
<!--        >-->
<!--          +-->
<!--        </button>-->
          </base-button>
      </div>
    </div>
<!--    <base-button class="btn btn-primary" @click="checkout()">Checkout</base-button>-->
  </modal>
</template>

<script>
import {Stripe} from "stripe"
import {stripeSk, stripeSKTest} from "@/main"
import BaseButton from "@/components/BaseButton.vue"
import Modal from "@/components/Modal.vue"
export default {
  name: "CartModal",
  components: {BaseButton, Modal},
  props: {
    isOpen: {
      default: false,
      type: Boolean
    }
  },
emits: ["isOpen", "closeModal"],
  data() {
    return {
      products: [
        {
          name: "Sport Nitrate",
          image: require("@/assets/images/beetit-nitratesport.png"),
          productId: "prod_OAIm33kiv64jDy",
          quantity: 0,
          link: "https://buy.stripe.com/7sIcN95vGatO1iMaEF"
        },
        {
          name: "Sport Nitrate & Regen Cherry",
          image: require("@/assets/images/both_products.png"),
          productId: "prod_QBSQqJq6BjsJFe",
          quantity: 0,
          link: "https://buy.stripe.com/28odRd3ny45q0eI5kn"
        },
        {
          name: "Regen Cherry",
          image: require("@/assets/images/regen-cherry-shot-x200.png"),
          productId: "prod_QBSQqJq6BjsJFe",
          quantity: 0,
          link: "https://buy.stripe.com/fZe3cz9LWgSc9Pi3ce"
        }
      ],
      cart: []
    }
  },
  methods: {
    closeModal () {
      console.log("closeModal")
      this.$emit("isOpen", false)
      this.$emit("closeModal")
    },
    addProductToCart(product) {
      this.cart.push(product)
    },
    addCurrentProduct (product) {
      this.cart.push(product)
    },
    async checkout() {
      try {
        const stripe = new Stripe(stripeSKTest, {
          apiVersion: "2023-08-16",
        })

        let products = []
        this.products.forEach(product => {
          const prod = {
            price: (product.price / 100).toString(),
            quantity: 1
          }
          products.push(prod)
        })

        let price = await stripe.prices.create({
          currency: "eur",
          unit_amount: this.invoiceData.amount.total,
          product: process.env.VUE_APP_STRIPE_SHIFT_PRODUCT
        })

        const params = {
          products: "",
          success_url: window.location.origin + "/invoices",
          cancel_url: window.location.origin + "/invoices",
          mode: "payment",
          line_items: [{
            quantity: 1,
            price: price.id
          }],
          custom_text: {
            submit: {
              message: "The invoice will be marked as paid as soon as we confirm the payment."
            }
          },
          payment_intent_data: {
            metadata: {
              userId: this.$store.state.user.claims.user_id,
              phoneNumber: this.$store.state.user.claims.phone_number,
              invoiceId: this.invoiceData.id,
              invoiceRef: this.invoiceData.invoiceRef
            },
          }
        }

        if (userPrivate.hasOwnProperty("email") && userPrivate.email !== null) {
          params.customer_email = userPrivate.email
        }

        let checkoutSession = await stripe.checkout.sessions.create(params)
        window.location.href = checkoutSession.url
        // console.log("result", result)

    } catch (e) {
      this.$swal.hideLoading()
      this.$swal.fire({
        icon: "error",
        html: e.message + " <br> <b>Please contact support.</b>",
        title: "Something went wrong!",
      })
    }
    }
  }
}
</script>
<style scoped>
.listOfProducts {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
}

.product {
  //width: 300px;
  background-color: #fff;
  list-style: none;
  box-sizing: border-box;
  padding: 1em;
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
}

.product-name {
  font-size: 1.2em;
  font-weight: normal;
}

.product-name:hover {
  //cursor: pointer;
  //text-decoration: underline;
}

.product-price {
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: .5em;
}

.product.col-md-4:hover {
  background-color: #421f2061;
}

</style>