<template>
  <div>
    <div class="bg-cherry-image" style="    padding: 16px 0;">
      <div class="container-- p-2" style="bottom: 10px;left: 0;right: 0;">
        <div class="row justify-content-md-start">
          <div class="" style="background: rgba(255, 255, 255, 0.4509803922);">
          <span class="main-text p-3" style="font-size: 5em; color: rgb(110 19 24)">
            Regen Cherry
         </span>
<!--            <br>-->
<!--            <base-button tag="a" href="#how-it-works"   type="primary" size="bg">Find out how</base-button>-->
          </div>
        </div>
      </div>
    </div>
    <section class="p-lg-5 p-2">
      <div id="why-cherry" class="row" style="width: 100%;padding-top: 150px;margin-top: -150px">
        <div class="col-md-7" style="font-size: 2em">
          <div style="font-size: 0.6em" class="text-start p-md-5 p-2">
            <h1>Why Cherry?</h1>

            <p class="fw-bold">
              In the realm of sports nutrition, our mission for safe, effective supplements is paramount. With Beet It Regen Cherry+, we bring you a product rooted in scientific research and proven efficacy.
            </p>
            <p>
              Numerous investigations have underscored the potential of sour cherry juice to bolster antioxidant defences, combat inflammation, and support overall well-being. Athletes across disciplines have embraced its use as a convenient, all-in-one solution to optimize training outcomes and accelerate the recovery process.
            </p>

          </div>
        </div>

        <div class="col-md-5 text-start d-none d-md-block ">
          <img style="      width: 100%;  border-radius: 1px 163px;" src="@/assets/images/BIS-Regen-lifestyle-21.jpg" alt="">
        </div>
      </div>
    </section>
    <section class="p-lg-5 p-2">
      <div id="why-cherry" class="row" style="width: 100%;padding-top: 150px;margin-top: -150px">
        <div class="col-md-5 d-none d-md-block text-start position-relative">
          <img style="    width: 100%;position: absolute;top: 0;bottom: 0;margin: auto;border-radius: 1px 163px;" src="@/assets/images/BIS-Regen-lifestyle-recover.jpg" alt="">
        </div>
        <div class="col-md-7" style="font-size: 2em">
          <div style="font-size: 0.6em" class="text-start p-md-5 p-2">
            <h1>Pre-covery</h1>
            <p class="fw-bold">
              Whilst Beet It Regen Cherry+ is our recovery shot, the science behind sour cherry juice suggests that it is a “precovery” supplement.
              This means that, for optimum benefits, sour cherry juice should be introduced to athletes’ diets on the days prior to exercise to facilitate recovery on the days after exercise.
            </p>
            <p>
              When supplementing Beet It Regen Cherry+, precovery is essential, given that the antioxidant activity in cherries takes a while to take effect (i.e., days rather than hours).
              The bulk of the scientific literature on sour cherry juice indicates that the optimal supplementation period includes supplementation on the days before, the day of, and two days after a performance exercise session or competition.
            </p>
            <p>
              In accordance with the International Olympic Committee, the recommended dosing schedule for Beet It Regen Cherry+ shot is one shot per day for 3-4 days before, one shot on the event day, and one shot daily for the two days following the event.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="row p-lg-5 p-2">
      <div class="col-md-6 text-start">
        <h1>Dosing Guidelines</h1>
        <div>
          <p class="fw-bold">
            The below dosing guidelines illustrate the recommended use of Beet It Sport Nitrate 400mg for pre-performance enhancement and Beet It Regen Cherry+ for pre-recovery support.
          </p>
          <p>
            This combination approach ensures you’re prepared for your athletic endeavour and supported in your subsequent recovery.

          </p>
        </div>
        <img style="max-width:700px;width:100%;" src="../assets/images/dosing-guidelines.png" />

      </div>
      <div class="col-md-6 d-none d-md-block">
        <img style="max-width: 550px;width: 100%;border-radius: 308px 0 3px 77px;" src="../assets/images/Cross-fit-1-web.jpg">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "NitrateSport",
  data() {
    return {
      modalBool: false
    }
  },
}
</script>

<style>

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1030px;
  }
}

.search-state-indicator:before {
  background-color: #fff;
}

body {
  background: #0080804a;
}

.modal.show .modal-dialog {
  min-width: 70% !important;
}

h2.accordion-header button {
  font-weight: 800 !important;
}

.accordion-body {
  text-align: left;
}

.bg-cherry-image{
  background-image: url(@/assets/images/cherry-regen.jpg) !important;
  background-size: cover;
  background-position: 0 0px;
  background-color: rgba(228, 233, 238, 0.36);
  background-repeat: no-repeat;
  /* min-height: 500px; */
  background-attachment: fixed;
}


h1, h2 {
  color: #dc3545; /* Bootstrap's danger color */
}
.references ol {
  padding-left: 20px;
}
.image {
  text-align: center;
  margin-top: 20px;
}
.image img {
  max-width: 100%;
  height: auto;
}

</style>