import { createSSRApp } from "vue"
import App from "./App.vue"
import { store } from "./store"
import router from "./router"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFirestore } from "firebase/firestore"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
// import "bootstrap"
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"
import "./assets/@fortawesome/fontawesome-free/css/all.min.css"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import moment from "moment"
import i18n from "./lang/lang.js"
import Multiselect from "vue-multiselect"
import "./assets/scss/style.scss"
const firebaseConfig = {
    apiKey: "AIzaSyDKINav5fItUK1_W_MRNkXOK23azSRn1gU",
    authDomain: "beetitcy.firebaseapp.com",
    projectId: "beetitcy",
    storageBucket: "beetitcy.appspot.com",
    messagingSenderId: "426808329505",
    appId: "1:426808329505:web:302282b203f1d429952a60",
    measurementId: "G-PWHVNV8L93",
    stripeSKTest: "sk_test_51NL8oeAKIVPD9UkrJVaZIp4BDOvFd3iwz0dOquvpU89WXN3Do3jgyZPwVFLtgE92AJwYNVodEcawoCQpACj54i1a00bI42RBNE",
    stripeSk: "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"
}
export const stripeSk = firebaseConfig.stripeSk
export const stripeSKTest = firebaseConfig.stripeSKTest
export const fireApp = initializeApp(firebaseConfig)
export const analytics = getAnalytics(fireApp)
export const auth = getAuth(fireApp)

onAuthStateChanged(auth, async (user) => {
    if (user == null) {
        await store.dispatch("signOut")
    }



    // if ((store.state.user.data == null || store.state.user.claims == null) && user !== null) {
    //     await store.dispatch("fetchUser", user);
    //     // eslint-disable-next-line no-prototype-builtins
    // }
})

router.beforeEach(async (to, from, next) => {
    let currentUser = await store.dispatch("isLoggedIn")
    let requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
    // console.log("requiresAuth", requiresAuth)
    // console.log("currentUser", currentUser)
    if (!requiresAuth) {
        next()
        return
    }

    if (requiresAuth && currentUser) {
        next()
    } else {
        next("login")
    }

    console.log("Should not be here", requiresAuth)
})

export const firestore = getFirestore()
const appInstance = createSSRApp(App)
// eslint-disable-next-line vue/multi-word-component-names
appInstance.component("multiselect", Multiselect)
appInstance.component("VueDatePicker", VueDatePicker)
appInstance.use(router)
appInstance.use(store)

moment.locale(store.state.locale)
appInstance.config.globalProperties.$moment = moment

appInstance.use(moment)
appInstance.config.devotools = true
appInstance.use(i18n)
appInstance.use(VueSweetalert2)
appInstance.mount("#app")