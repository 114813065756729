import { createRouter, createWebHistory } from "vue-router"

// no auth views
import Home from "../views/Home.vue"
import TopLayout from "@/layout/TopLayout"
import Lost from "../views/404.vue"
import BackedByScience from "@/views/BackedByScience"
import AboutUs from "@/views/AboutUs"
import DosingGuidelines from "@/views/DosingGuidelines"
import NitrateSport from "@/views/NitrateSport"
import CherryRegen from "@/views/CherryRegen.vue"

const routes = [
  {
    path: "/",
    component: TopLayout,
    children: [
      {
        path: "/",
        name: "home",
        components: { default: Home },
      },
      {
        path: "/nitrate-sport",
        name: "NitrateSport",
        components: { default: NitrateSport },
      },
      {
        path: "/cherry-regen",
        name: "CherryRegen",
        components: { default: CherryRegen },
      },
      {
        path: "/backed-by-science",
        name: "BackedByScience",
        components: { default: BackedByScience },
      },
      {
        path: "/about-us",
        name: "AboutUs",
        components: { default: AboutUs },
      },
      {
        path: "/dosing-guidelines",
        name: "DosingGuidelines",
        components: { default: DosingGuidelines },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "lost",
        components: { default: Lost },
      },
      {
        path: "/error",
        name: "error",
        components: { default: Lost },
      }
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
})

export default router
