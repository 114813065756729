import {createStore} from "vuex"
import VuexPersistence from "vuex-persist"
import {getAuth} from "firebase/auth"
import {fireApp} from "@/main"
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore"

// function getDocReference(path, id) {
//   let fireS = getFirestore(fireApp);
//   return doc(collection(fireS, path), id);
// }

// function getDocEl(path, id) {
//   let fireS = getFirestore(fireApp);
//   return doc(fireS, path, id);
// }
//
// function getCollectionGroupReference(path) {
//   let fireS = getFirestore(fireApp);
//   return collectionGroup(fireS, path);
// }

export const store = createStore({
  plugins: [new VuexPersistence().plugin],
  state: {
    locale: "en",
    showLogin: false,
    user: {
      claims: null,
      loggedIn: false,
      data: null,
      clientData: null
    },
  },
  mutations: {
    SET_LOCALE (state, lang) {
      state.locale = lang
    },
    RESET_STATE(state) {
      state.user = {
        claims: null,
            loggedIn: false,
            data: null,
      }
    },
    SET_CLIENT(state, data) {
      state.user.clientData = data
    },
    SET_LOGGED_IN(state, data) {
      state.user.loggedIn = data
    },
    SET_VEHICLES(state, data){
      state.vehicles = data
    },
    SET_USER(state, data) {
      state.user.data = data
    },
    SET_USER_PRIVATE(state, data) {
      state.user.private = data
    },
    SET_USER_CLAIMS(state, data) {
      state.user.claims = data
    },
    SET_ROUTES(state, data) {
      state.routes = data
    },
    SET_STATISTICS(state, data) {
      state.system.statistics = data
    },
    GET_COMPANY(state, data) {
      state.company = data
    },
    GET_BOOKING_SETTINGS(state, data) {
      state.bookkingSettings = data
    },
    GET_COMPANY_STATISTICS(state, data) {
      state.companyStatistics = data
    },
    SET_SYSTEM_USERS(state, data) {
      state.system.systemUsers = data
    },
    SET_COMPANIES(state, data) {
      state.system.companies = data
    },
  },
  getters: {},
  actions: {
    setLocale ({ commit }, lang) {
      commit("SET_LOCALE", lang)
    },

    async getClaims() {
      return await getAuth(fireApp)
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          return idTokenResult.claims
        })
        .catch((error) => {
          console.log(error)
          return null
        })
    },
    async getRouteList({ commit }) {
      let fireS = getFirestore(fireApp)
      let docRef = doc(fireS, "info/routes")
      let docReq = await getDoc(docRef)
      if (docReq.exists()) {
        let docData = docReq.data()
        const res = Object.entries(docData.list).map(([key, value]) => ({ "key": key, "value": value }))
        commit("SET_ROUTES", res)
        return res
      }
    },
    // eslint-disable-next-line no-unused-vars
    async setAvailableRoutes({commit}, data) {
      let fireS = getFirestore(fireApp)
      let refDoc = doc(fireS, "info/routes")

      let list = {}
      data.forEach(item => {
        list[item] = titleCase(item)
      })

      await setDoc(refDoc, {
        list: list
      })
    },
    isLoggedIn({ commit }) {
      if (this.state.user.loggedIn){
        return true
      }
      let currUser = getAuth(fireApp).currentUser
      commit("SET_LOGGED_IN", currUser != null)
      return currUser != null
    },
    signOut({ commit }) {
      return getAuth(fireApp)
        .signOut()
        .then(() => {
          // console.log("logged out");
          commit("RESET_STATE")
          return true
        })
    },
    // eslint-disable-next-line no-unused-vars
    async fetchRoutes({commit}, data) {
      let fireS = getFirestore(fireApp)
      let collectionRef = collection(fireS, "routes")
      const docsQuery = query(
        collectionRef,
        where("vehicle.maxPeople", ">=", data.model.persons),
        where(data.toggle ? "to" : "from", "==", data.model.departure.key),
        where(data.toggle ? "from" : "to", "==", data.model.destination.key),
        limit(20)
      )

      return await getDocs(docsQuery).then((docs) => {
        let routes = []
        docs.forEach((doc) => {
          let data = doc.data()
          data["id"] = doc.id
          routes.push(data)
        })
        return routes
      })
    },
    async fetchDocs({commit}, data) {
      let fireS = getFirestore(fireApp)
      let collectionRef = collection(fireS, data.path)
      let docsQuery = query(
          collectionRef,
          limit(500)
      )

      if(data.from != null){
        docsQuery = query(
            docsQuery,
            where("from", "==", data.from),
        )
      }
      if(data.to != null){
        docsQuery = query(
            docsQuery,
            where("to", "==", data.to),
        )
      }

      return await getDocs(docsQuery).then((docs) => {
        let vehicles = []
        docs.forEach((doc) => {
          let data = doc.data()
          data["id"] = doc.id
          vehicles.push(data)
        })
        if(data.path === "vehicles") {
          commit("SET_VEHICLES", vehicles)
        }
        return vehicles
      })
    },
    // eslint-disable-next-line no-unused-vars
    async deleteDocument({commit}, data) {
      let fireS = getFirestore(fireApp)
      await deleteDoc(doc(fireS, data.path, data.id))

    },
    // eslint-disable-next-line no-unused-vars
    async saveDocs({commit, dispatch}, data) {


      let fireS = getFirestore(fireApp)
      const batch = writeBatch(fireS)

      for (const row of data.items) {

        let docRef
        if(row.id) {
          docRef = doc(fireS, data.path+"/"+ row.id)
        } else {
          docRef = doc(collection(fireS, data.path))
        }

        // eslint-disable-next-line no-prototype-builtins
        if(data.path === "vehicles" && row.hasOwnProperty("file") && row.file !== null){
          const storage = getStorage()
          const vehiclesRef = ref(storage, "vehicles/"+docRef.id + "_" + row.file.name)
          let result = await uploadBytes(vehiclesRef, row.file)
          delete row.file
          row.image = await getDownloadURL(result.ref)
        }

        row.from = row.from.trim()
        row.to = row.to.trim()

        batch.set(docRef, row)
      }

      if(data.path === "routes") {
          let availableRoutes = data.items.map(val => val.from.trim())
        availableRoutes = availableRoutes.concat(data.items.map(val => val.to.trim()))
        availableRoutes = availableRoutes.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a},[])
        await dispatch("setAvailableRoutes",availableRoutes)
      }

      await batch.commit()
    },
    // eslint-disable-next-line no-unused-vars
    async submitTransfer({ commit }, data) {
      let fireS = getFirestore(fireApp)
      return await addDoc(collection(fireS, "transfers"), data)
    },
  },
  modules: {},
})

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(" ")
}