<template>
  <div class="home">
    <div id="carouselExampleDark" class="carousel carousel-light slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active carousel-cherry-regen-img" data-bs-interval="300000">
          <div class="carousel-caption d-md-block">
            <div class="carousel-item-prod-image d-block d-lg-none">
              <img width="200" src="@/assets/images/beetit-nitratesport.png">
            </div>
              <span class="main-text" style="font-size: 5em;text-shadow: 0px 4px 8px rgb(0 0 0)!important;">
              Sport Nitrate 400
            </span> <br>
            <router-link class="btn btn-outline-white" to="/nitrate-sport">LEARN MORE</router-link>
<!--            <h5>Second slide label</h5>-->
<!--            <p>Some representative placeholder content for the second slide.</p>-->
          </div>
        </div>
        <div class="carousel-item carousel-bis-regen-img" data-bs-interval="3000">
          <div class="carousel-caption d-md-block">
            <div class="carousel-item-prod-image d-block d-lg-none">
              <img width="300" src="@/assets/images/regen-cherry-shot-x200.png">
            </div>
            <span class="main-text" style="font-size: 5em;text-shadow: 0px 4px 8px rgb(0 0 0)!important;">
              Regen Cherry
            </span> <br>
            <router-link class="btn btn-outline-white" to="/cherry-regen">LEARN MORE</router-link>
            <!--            <h5>Second slide label</h5>-->
            <!--            <p>Some representative placeholder content for the second slide.</p>-->
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
<!--    <div class="bg-image p-4">-->
<!--    <div class="container&#45;&#45; p-2" >-->
<!--      <div class="row justify-content-md-start">-->
<!--       <div class="col-md-6 text-start ">-->
<!--          <span class="main-text" style="font-size: 5em;">Beet <br>-->
<!--          Your<br>-->
<!--          Personal<br>-->
<!--          Best<br></span>-->
<!--<br>-->
<!--         <base-button tag="a" href="#how-it-works"   type="primary" size="bg">Find out how</base-button>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->

<!--    </div>-->

    <section class="p-lg-5 p-2" style="    background-image: linear-gradient(271deg, #8e282e 0%, #8c1485 100%);">
      <div id="our-products" style="    padding: 150px 20px 0;margin-top: -150px">
        <div class="col-12 text-uppercase pb-4 pt-4 fw-bold text-white" style="font-size: 2em;">Our Products</div>
        <div class="row">
        <div class="col-md-6">
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <div class="nitrate-sport-img"></div>
<!--              <img src="../assets/images/regen-cherry-shot-x200.png" class="img-flcdcuid rounded-start" alt="...">-->
            </div>
            <div class="col-md-8">
              <div class="card-body text-md-end text-start">
                <h4 class="card-title fw-bold" style="color: #ed018c">Sport Nitrate 400</h4>
                <p class="card-desc">
                  A guaranteed daily dose of dietary nitrate packed into a
                  pocket-sized 70ml shot.
                </p>
                <p class="card-ingredients">
                  <b style="color: #ed018c">Ingredients</b> <br>
                  <span>
                    Concentrated beetroot juice (98%) <br>
                  Lemon juice (2%)
                  </span>
                </p>
                <p>
                  <base-button tag="a" href="https://buy.stripe.com/7sIcN95vGatO1iMaEF"
                               type="primary" block size="lg"
                  >
                    {{ $t("Order Now") }}
                  </base-button>
                </p>
<!--                <p class="card-text">-->
<!--                  <small class="text-muted">-->
<!--                    BEETROOT JUICE FOR-->
<!--                    ATHLETIC PERFORMANCE-->
<!--                  </small>-->
<!--                </p>-->
                <div class="card-footer">
                  <small class="text-muted">
                    BEETROOT JUICE FOR
                    ATHLETIC PERFORMANCE
                  </small>
                </div>
                <p>
                  <router-link class="btn btn-link" style="color: #ed018c;font-weight: 800;" to="/nitrate-sport">Learn more</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-8">
              <div class="card-body text-start">
                <h4 class="card-title fw-bold" style="color: #b9252d;">Cherry Regen</h4>
                <p class="card-desc">
                  Regen Cherry offers a powerful anti-oxidant and anti-inflammatory blend shot of sour cherry and beetroot, enhancing recovery process.</p>
                <p class="card-ingredients">
                  <b style="color: #b9252d;">Ingredients</b> <br>
                  <span>
                    Concentrate sour cherry juice (58%) <br> Concentrate beetroot juice (42%).
                  </span>
                </p>
                <p>
                  <base-button tag="a" href="https://buy.stripe.com/fZe3cz9LWgSc9Pi3ce"
                               type="primary"
                               block size="lg"
                               style="background: #b9252d;"
                  >
                    {{ $t("Order Now") }}
                  </base-button>
                </p>
<!--                <p class="card-text">-->
<!--                  <small class="text-muted">-->
<!--                    SOUR CHERRY & BEETROOT JUICE FOR RECOVERY-->
<!--                  </small>-->
<!--                </p>-->
                <div class="card-footer">
                  <small class="text-muted">
                    SOUR CHERRY & BEETROOT JUICE FOR RECOVERY
                  </small>
                </div>
                <p>
                  <router-link class="btn btn-link" style="color: rgb(185, 37, 45);font-weight: 800;" to="/cherry-regen">Learn more</router-link>
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="cherry-shot-img"></div>
<!--              <img src="../assets/images/beetit-nitratesport.png" class="idcmg-fluid rounded-start" alt="...">-->
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="">
        <card
            no-body
            shadow-size="lg"
            class="border-0 bg-primary"
            style="border-radius: 0;"
        >
          <div class="p-5  nutrition-image">
            <div class="row align-items-center justify-content-md-center">
              <div class="col-md-9 text-md-star" >
                <div class="row align-items-center">
                  <div class="col-lg-8 text-md-start">
                    <h3 class="text-white">{{ $t("Nutrition Supplement") }}</h3>
                    <p class="lead text-white mt-3">
                      {{ $t("We deliver to Cyprus.") }}<br>
                      {{ $t("Order yours today!") }}
                    </p>
                  </div>
                  <div class="col-lg-3 ml-lg-auto text-md-end">

                    <base-button class="fw-bold" @click="modalBool = true" type="primary">{{ $t("Order Now") }}</base-button>
                    <cart-modal :is-open="modalBool" @close-modal="modalBool=false"></cart-modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>

    <section class="p-md-5 p-2" style="overflow: hidden;background: linear-gradient(0deg, #fcb5258f, white);">
      <div class="row justify-content-md-center">
        <div class="col col-md-6">
      <div class="col-12 text-uppercase pb-4 fw-bold" style="font-size: 2em;">Certified Product</div>

        <div>
          Beet It Sport products carry the Informed Sport logo, which means they have been test by LGC’s
          world-class sports anti- doping laboratory to ensure product trust and integrity.
          With Informed Sport, athletes can be confident their reputation is in safe hands.
        </div>
        <img class="p-2" width="200" src="../assets/images/Informed.png" />
        <div class="col-12 text-uppercase pb-4 fw-bold" style="font-size: 15px;">
          <a target="_blank" class="btn btn-gold" href="https://sport.wetestyoutrust.com/supplement-search/beet-it/beet-it-sport-nitrate-400-concentrated-beetroot-shot?search=&view_type=grid_layout&order=field_test_date&sort=desc">Check our Batches</a>
        </div>
      </div>
      </div>
    </section>
    <section class="section section-lg">
      <card
          no-body

          shadow-size="lg"
          class="border-0 "
          style="border-radius: 0;"
>
        <div class="p-5">
          <div class="row align-items-center justify-content-md-center">
            <div class="col-md-9 text-md-start">
              <div class="row align-items-center">
                <div class="col-lg-7">
                  <h4 class="mb-1"> {{ $t("Thank you for your support!") }}</h4>
                </div>
                <div class="col-lg-5 me-lg-auto my-lg-auto text-lg-end mt-5">
                    <base-button target="_blank" href="https://www.instagram.com/beetitcyprus/" icon="fa-brands fa-instagram" class="btn btn-primary border-0" tag="a"> Instagram</base-button>
<!--                    <base-button icon="fa-brands fa-instagram" class="btn btn-primary instagram border-0 ms-2" tag="a"> Instagram</base-button>-->
</div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </section>
</div>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import Card from "@/components/Card"
import BaseButton from "@/components/BaseButton"
import Modal from "@/components/Modal"
import CartModal from "@/components/CartModal.vue"

export default {
  name: "home",
  components: {CartModal, BaseButton, Card},
  data() {
    return {
      modalBool: false
    }
  },
}
</script>
<style>

@media (max-width: 768px) {
  .carousel-caption span.main-text {
    font-size: 3em !important;
  }
}
.carousel-caption{
  right: 5% !important;
  left: 5% !important;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }

  p.card-desc {
    min-height: 75px !important;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1030px;
  }
}

.search-state-indicator:before {
  background-color: #fff;
}

body {
  background: #0080804a;
}

.modal.show .modal-dialog {
  min-width: 70% !important;
}

h2.accordion-header button {
  font-weight: 800 !important;
}

.accordion-body {
  text-align: left;
}

.cherry-shot-img {
  background-image: url(@/assets/images/regen-cherry-shot-x200.png);
  background-repeat: no-repeat;
  height: 100%;
  background-position: -47px 39px;
}


.nitrate-sport-img {
  background-image: url(@/assets/images/beetit-nitratesport.png);
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-position: 0 19px;
  background-size: 210px;
}
.nutrition-image {
  background-image: url(@/assets/images/hp_beetIt.jpg);
  background-size: cover;
  background-position: 0px -200px;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.col-md-6 .our-product {
  height: 400px;
  background: #8c17841a;
  transition: 0.3s;
  border-radius: 20px;
}
.col-md-6 .our-product:hover {
  background: #8c178447;
}

.our-product .col-md-6 {
  height: 100%;
}

p.card-desc {
  min-height: 95px;
}

.carousel-cherry-regen-img{
  background-image: url(@/assets/images/Jamal-new-branding1-x1000px.jpg);
  height: calc(100vh - 88px);
  //background-size: 100%;
  background-position: 0 -100px;
  background-repeat: no-repeat;
}
.carousel-bis-regen-img{
  background-image: url(@/assets/images/bis-regen.jpg);
  height: calc(100vh - 88px);
  //background-size: 100%;
  background-position: 0 -100px;
  background-repeat: no-repeat;
}

@media (min-width: 1500px) {
  .carousel-bis-regen-img, .carousel-cherry-regen-img {
    background-size: cover !important;
  }
}
</style>

