<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-gradient-gray">
    <div class="container container-fluid">
      <a class="navbar-brand" href="/"><img  alt="BeetItCy logo" width="250" src="../assets/BIS_Logo.png" class=""/></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 text-white">
          <li class="nav-item">
            <a class="nav-link" href="/#our-products">
              Our Products
            </a>
          </li>
          <li class="nav-item">
            <router-link to="/backed-by-science" class="nav-link">
              Backed by Science
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <router-link to="/about-us" class="nav-link">
              About Us
            </router-link>
          </li>
        </ul>
        <base-button class="fw-bold" @click="buyModal = true" type="white">Order Now</base-button>
        <cart-modal :is-open="buyModal" @close-modal="buyModal=false"></cart-modal>
      </div>
    </div>
  </nav>
<!--  <nav-->
<!--      class="navbar navbar-expand-lg sticky-top"-->
<!--      :class="[-->
<!--      { 'navbar-expand-md': expand },-->
<!--      { 'navbar-transparent': transparent },-->
<!--      { [`bg-${type}`]: type },-->
<!--    ]"-->
<!--  >-->
<!--    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">-->
<!--      <span class="navbar-toggler-icon"></span>-->
<!--    </button>-->
<!--    <div :class="containerClasses">-->
<!--      <slot name="brand">-->
<!--        <a href="/" class="sm-left">-->
<!--        <img  alt="apoointme logoo" width="250" src="../assets/BIS_Logo.png" class=""/>-->

<!--&lt;!&ndash;        <label style="cursor: pointer" class="h3 mb-0 text-white navbar sticky-top navbar-expand-md navbar-top navbar-horizontal bg-gradient-primary d-lg-inline-block">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="fa fa-bus-alt p-1"></i> {{ title }}&ndash;&gt;-->

<!--&lt;!&ndash;        </label>&ndash;&gt;-->
<!--        </a>-->
<!--&lt;!&ndash;        <label class="h1 mb-0 text-white  d-none d-lg-inline-block pl-2" v-if="$route.name === 'QuickBook'">Book your Appointment</label>&ndash;&gt;-->
<!--</slot>-->
<!--      <div>-->
<!--        <base-button class="custom-nav-link m-lg-4 mb-hide" tag="a" href="#how-it-works" type="link" block size="lg" style="    color: white;text-decoration: none;">How it works</base-button>-->
<!--        <base-button class="custom-nav-link m-lg-4 mb-hide" tag="a" href="#how-it-works" type="link" block size="lg" style="    color: white;text-decoration: none;">Backed by science</base-button>-->
<!--        <base-button class="custom-nav-link m-lg-4 mb-hide" tag="a" href="#how-it-works" type="link" block size="lg" style="    color: white;text-decoration: none;">About</base-button>-->
<!--      </div>-->
<!--      <div class=" locale-changer float-right">-->
<!--        <base-button tag="a" target="_blank" href="https://buy.stripe.com/test_4gw4jL0ny6pU4eY7ss" type="white">Order Now</base-button>-->
<!--&lt;!&ndash;<multiselect&ndash;&gt;-->
<!--&lt;!&ndash;            v-model="locale"&ndash;&gt;-->
<!--&lt;!&ndash;            style="max-width: 160px"&ndash;&gt;-->
<!--&lt;!&ndash;            :searchable="false"&ndash;&gt;-->
<!--&lt;!&ndash;            :options="locales"&ndash;&gt;-->
<!--&lt;!&ndash;            placeholder="Select Language"&ndash;&gt;-->
<!--&lt;!&ndash;            label="label"&ndash;&gt;-->
<!--&lt;!&ndash;            :show-labels="false"&ndash;&gt;-->
<!--&lt;!&ndash;            track-by="value"&ndash;&gt;-->
<!--&lt;!&ndash;            class=""&ndash;&gt;-->
<!--&lt;!&ndash;            @select="setLocale()"&ndash;&gt;-->
<!--&lt;!&ndash;        ></multiselect>&ndash;&gt;-->
<!--</div>-->

<!--&lt;!&ndash;      <button v-if="$store.state.user.loggedIn && !['home', 'search', 'checkout'].includes($route.name)" class="btn btn-primary" @click="logOut">Log Out</button>&ndash;&gt;-->
<!--    </div>-->
<!--  </nav>-->
</template>
<script>
import "moment/locale/el"
import packageData from "../../package.json"
import BaseButton from "@/components/BaseButton"
import CartModal from "@/components/CartModal.vue"

export default {
  name: "base-nav",
  components: {
    CartModal,
    BaseButton
    // BaseButton,
    // BaseDropdown,
  },
  props: {
    type: {
      type: String,
      default: "",
      description: "Navbar type (e.g default, primary etc)",
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar",
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description:
          "Explicit id for the menu. By default it's a generated random number",
    },
    containerClasses: {
      type: [String, Object, Array],
      default: "container-fluid",
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent",
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain `navbar-expand-lg` class",
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      buyModal: false,
      locale: "",
      toggled: false,
      locales: [
        {label: "English", value: "en"},
        {label: "Ελληνικά", value: "el"},
        // {label: 'Russian', value: 'ru'}
      ],
    }
  },
  mounted() {
    this.version = packageData.version
    let localeSelected = this.locales.find( l => l.value === this.$store.state.locale)

    if(localeSelected){
      this.locale = localeSelected
    }
  },
  methods: {
    setLocale () {
      // this.$store.state.locale = value
      this.$i18n.locale = this.locale.value.toLowerCase()
      this.$store.dispatch("setLocale", this.locale.value.toLowerCase())
    },
    closeMenu() {
      this.toggled = false
    },
    closeLangMenu() {
      this.langToggled = false
    },
    async logOut() {
      let result = await this.$store.dispatch("signOut")
      this.$router.push({name: "home"})

    }
  },
}
</script>
<style>
@media(min-width: 767px)  {
  .navbar-expand-md .navbar-collapse.mb-only{
    display: none !important;
  }
}



.locale-changer .multiselect__single {
    background: transparent;
  }

.locale-changer .multiselect__tags {
    background: transparent;
    color: white;
    border: none;
  }

.locale-changer .multiselect__select::before {
  border-color: #ffffff transparent transparent transparent;
  }

.locale-changer .multiselect__content-wrapper {
  border: none;
}

.navbar .nav-item a {
  color: white !important;
  font-weight: 500;
  font-size: larger;
}

.navbar .nav-item a:hover, .navbar .nav-item a.active.router-link-exact-active.nav-link {
  background-image: linear-gradient( to right, #8c1485, #af43a9 50%, #fff 50% );
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  text-shadow: 0px 0px 2px #f9f9f9;
}

.navbar .nav-item a.active.router-link-exact-active.nav-link {
  text-decoration: underline !important;
  -webkit-text-fill-color: snow !important;
}
</style>
