<template>
  <div>
    <div class="nitrate-sport-bg">
      <div class="container-- p-2" style="bottom: 10px;left: 0;right: 0;">
        <div class="row justify-content-md-start">
          <div class="" style="background: rgba(255, 255, 255, 0.4509803922);">
          <span class="main-text p-3" style="font-size: 5em;">
            Sport Nitrate 400
         </span>
<!--            <br>-->
<!--            <base-button tag="a" href="#how-it-works"   type="primary" size="bg">Find out how</base-button>-->
          </div>
        </div>
      </div>
    </div>
    <section class="p-lg-5 p-2">
      <div class="row" style="width: 100%;padding-top: 150px;margin-top: -150px">
        <div class="col-12 text-uppercase pb-4 fw-bold" style="font-size: 2em;">How Does It Work?</div>
        <div class="col-md-7" style="font-size: 2em">
          <div style="font-size: 0.6em" class="text-start p-md-5 p-2">
            <ul >
              <li>After consuming <strong>nitrate</strong>-rich beetroot juice, <strong>nitrate</strong> is absorbed into the bloodstream and taken up by the salivary glands, before being converted to <strong>nitrite</strong> by bacteria in the mouth.</li>
              <li><strong>Nitrite</strong> is then swallowed, absorbed into the bloodstream and transported around the body. <strong>Nitrite</strong> can be converted to <strong>nitric oxide</strong> in the blood and in&nbsp;various organs including muscle.</li>
              <li><strong>Nitric oxide</strong> is important for several processes that influence exercise performance, including vasodilation (widening of blood vessels) and muscle contractility (increase in the force of contraction).</li>
              <li>A key effect of <strong>dietary nitrate</strong> is that it reduces the oxygen demand of exercise, meaning the muscles need less oxygen to maintain a given work rate, thereby improving exercise efficiency, fatigue resistance and exercise performance.</li>
            </ul>
            <p>In 1998, three scientists – R F Furchgott, L J Ignarro and F Murad – were awarded the <strong>Nobel Prize</strong> for the discovery of the importance of nitric oxide.</p>
            <base-button @click="modalBool = true" class="btn btn-primary how-it-works-modal-trigger mt-4" type="button">Watch the video<span class="visibly-hidden"> about how Beet It works</span></base-button>

            <!--             Video modal -->
            <modal :show="modalBool" @close="modalBool = false">
              <template #header>
                <h4 class="m-0">How Beet It works</h4>
              </template>
              <iframe title="vimeo-player" src="https://player.vimeo.com/video/352050582?h=467a2357e2" width="100%" height="450" frameborder="0"    allowfullscreen></iframe>
              <!--              <video id="howItWorks"  controls style="width: 100%;" autoplay>-->
              <!--                <source src="../assets/video/how-it-works-subtitled.mp4" type="video/mp4">-->
              <!--                Your browser does not support HTML video.-->
              <!--              </video>-->
            </modal>

          </div>
        </div>

        <div class="col-md-5" style="font-size: 2em">
          <img class="p-md-5 p-2" style="width: 100%" src="../assets/images/how-it-works-jun21.avif" />
        </div>
      </div>
    </section>
    <section class="row p-lg-5 p-2">
      <div class="col-md-6 text-start">
        <h1>Dosing Guidelines</h1>
        <div>
          <p class="fw-bold">
            Dosing guidelines are widely acknowledged to be between
            400mg and 800mg of dietary nitrate per day, consumed for
            three to six consecutive days, with a final dose consumed
            between 60 and 90 minutes prior to training
            and / or competition.
          </p>
          <p>
            Both acute (one day) and chronic (three to six consecutive
            days) supplementation has been shown to improve exercise
            performance10, although the latter dosing regimen is widely
            regarded as optimal for athletes looking to increase the
            likeliness of gaining a performance benefit.
          </p>
          <p>
            Chronic supplementation of dietary nitrate is thought to cause
            physiological adaptations in the skeletal muscle which would
            not otherwise occur with acute supplementation.
          </p>
        </div>
        <img class="" style="max-width:700px;width:100%" src="../assets/images/whentouse.jpg" />
        <div class="" style="font-size: 15px;">Consume 1 or 2 shots one to three hours before training and up to six days before competition</div>

      </div>
      <div class="col-md-6">
        <img style="max-width: 550px;width: 100%" src="../assets/images/Cross-fit-1-web.jpg">
      </div>
    </section>
    <section class="p-md-5 pt-4" style="overflow: hidden;">
      <div class="col-12 text-uppercase pb-4 fw-bold" style="font-size: 2em;">FREQUENTLY ASKED QUESTIONS</div>
      <div class="row justify-content-md-center">
        <div id="accordionExample" class="col col-md-10 accordion">
          <div class="accordion-item">
            <h2 id="headingOne" class="accordion-header">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Are there any side effects of consuming Beet It Sport products?
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Beet It Sport products either contain 100% concentrated
                beetroot juice (Nitrate 3000) or 98% concentrated beetroot
                juice with 2% lemon juice (Nitrate 400). Our products have
                been scientifically tested on thousands of athletes over the last
                decade and beeturia (pink urine and stools) is the only side
                effect reported; beeturia dissipates within 12 hours.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Can I use mouthwash while using Beet It Sport?
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Most anti-cavity fluoride-containing mouth rinses have no
                effect on the conversion of nitrate, however there is evidence
                to show that some antibacterial mouth rinses containing
                chlorhexidine might disrupt the conversion of nitrate to nitrite
                in the mouth. You should consider avoiding these if you are
                using Beet It Sport to improve performance.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Is synthetic / chemical nitrate as effective as natural nitrate?
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                No; dietary nitrate from beetroot juice has been shown to
                promote greater exercise benefits than synthetic forms of
                nitrate (potassium and sodium nitrate), possibly due to
                a number of other compounds in beetroot juice that have
                antioxidant properties (polyphenols and betacyanins) and
                which may increase the capacity for nitric oxide synthesis
                compared to synthetic / chemical forms of nitrate.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue"
import BaseButton from "@/components/BaseButton.vue"
import Card from "@/components/Card.vue"

export default {
  name: "NitrateSport",
  components: {Modal, BaseButton},
  data() {
    return {
      modalBool: false
    }
  },
}
</script>

<style>

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1030px;
  }
}

.search-state-indicator:before {
  background-color: #fff;
}

body {
  background: #0080804a;
}

.modal.show .modal-dialog {
  min-width: 70% !important;
}

h2.accordion-header button {
  font-weight: 800 !important;
}

.accordion-body {
  text-align: left;
}

.nitrate-sport-bg {
  background-image: url(@/assets/images/Jamal-new-branding1-x1000px.jpg);
  background-size: cover;
  background-position: 0 0px;
  background-color: rgba(228, 233, 238, 0.36);
  height: 100%;
  background-repeat: no-repeat;
  /* min-height: 500px; */
  background-attachment: fixed;
}


</style>